import Request from './request'
import addKeyLocalStorage from 'helper/localStorage'

export default class BookingService {
  static async AddBooking({ time, dateSchedule, email, fullnameSchedule, phone, licensePlates, notificationMethod }) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/userInsertSchedule',
        data: {
          licensePlates: licensePlates,
          phone: phone,
          fullnameSchedule: fullnameSchedule,
          email: email,
          dateSchedule: dateSchedule,
          time: time,
          stationUrl: window.origin.split('://')[1],
          notificationMethod: notificationMethod
        }
      }).then((result = {}) => {
        const { statusCode } = result
        if (statusCode === 200) {
          return resolve({ issSuccess: true })
        } else {
          return resolve({ issSuccess: false })
        }
      })
    })
  }

  static async getDetailIntroductionPage() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationIntroduction/stationIntroductionDetail',
        data: {
          stationUrl: window.location.origin.split('://')[1]
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getScheduleDetail(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/getDetailSchedule',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false })
        }
      })
    })
  }

  static async getStationList(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getAllExternal',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getDetailStation(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getDetail',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async getBookingHours(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getListScheduleTime',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getBookingDate(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getListScheduleDate',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getStationAreaList() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getAllStationArea',
        data : {},
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async createSchedule(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/createSchedule',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
  static async updateSchedule(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/updateSchedule',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result;
        if (statusCode === 200) {
          return resolve(result);
        } else {
          return resolve(result);
        }
      });
    });
  }

  static async createAskadviceSchedule(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/userCreateConsultant',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async findByHash(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/findByHash',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve({ ...result, isSuccess: true })
        } else {
          return resolve({ isSuccess: false })
        }
      })
    })
  }

  static async reviewSchedule(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerReview/user/addReview',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
  static async reviewStation(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerReview/user/addReviewStation',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async getMessageDetail(id) {
    const data = {
      id: parseInt(id)
    }
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerMessage/user/getDetailMessageById',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }

  static async getBookingHistory(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/getListSchedule',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          // kiểm tra : có phải lịch của user hãy không . nếu không bỏ qua nó
          const user = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))
          data.data = data.data.filter((item) => item.appUserId === user.appUserId)
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getBookingHistoryImport(data = {} , cancelEvent) {
    return new Promise((resolve) => {
      Request.sendImportExport({
        method: 'POST',
        path: '/CustomerSchedule/user/getListSchedule',
        data,
        cancelEvent
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          // kiểm tra : có phải lịch của user hãy không . nếu không bỏ qua nó
          const user = JSON.parse(localStorage.getItem(addKeyLocalStorage('data')))
          data.data = data.data.filter((item) => item.appUserId === user.appUserId)
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getBookingDetail(id) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/getDetailSchedule',
        data: {
          customerScheduleId: id
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async cancelBooking(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/cancelSchedule',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getRoadFee(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/createRoadFeeSchedule',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getInsuranceFee(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerSchedule/user/createInsuranceFeeSchedule',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve({})
        }
      })
    })
  }


}
