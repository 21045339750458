import Request from './request'

export default class NewService {
  static async userGetLatestNew(skip) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getNewestList',
        data: {
          skip: skip || 0,
          limit: 10,
          stationsUrl: window.origin.split('://')[1],
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async userGetExpertNews(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getExpertNews',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async userGetPartnerUtilityNews(limit) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getPartnerUtilityNews',
        data: {
          skip: 0,
          limit: limit || 10,
          order:{
              key: 'ordinalNumber',
              value:"asc"
          }
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async userGetRecruitmentNews(data) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getRecruitmentNews',
        data: data || {
          skip: 0,
          limit: 10,
          order:{
              key: 'ordinalNumber',
              value:"asc"
          }
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async userGetHotNewList() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getHighLightNews',
        data: {
          skip: 0,
          limit: 10,
          order:{
              key: 'ordinalNumber',
              value:"asc"
          }
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async getPromotionNews() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getPromotionNews',
        data: {
          skip: 0,
          limit: 10,
          order:{
              key: 'ordinalNumber',
              value:"asc"
          }
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }
  static async getStationsList(filter) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getList',
        data: filter
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getBannerStationsList(filter) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/SystemPromoBanners/user/getList',
        data: filter
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getStationDetailByStationCode(filter) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/Stations/user/getDetailByStationCode',
        data: filter
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getListCategory() {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNewsCategory/user/getList',
        data: {
          skip: 0,
          limit: 20,
          stationsUrl: window.origin.split('://')[1]
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async userGetList(categoryId, skip, limit = 5) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/getNewsList',
        data: {
          skip: skip,
          limit: limit,
          stationsUrl: window.origin.split('://')[1],
          filter: {
            stationNewsCategories: categoryId
          }
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async userGetHotNew(skip) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/getHotNewsList',
        data: {
          skip: skip,
          limit: 20,
          stationsUrl: window.origin.split('://')[1]
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async userGetDetailById(id) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/getNewsDetail',
        data: {
          id
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getShare(id) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/increaseShare',
        data: {
          stationNewsId : id
        }
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getPartnerPromotionNews(data={}) { // mặc định {} để có payload
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/StationNews/user/getPartnerPromotionNews',
        data: data
      }).then((result = {}) => {
        const { statusCode, data } = result
        if (statusCode === 200) {
          return resolve(data)
        } else {
          return resolve({})
        }
      })
    })
  }

  static async getNotify(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerMessage/user/getListMessage',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getNotifyImport(data = {} , cancelEvent) {
    return new Promise((resolve) => {
      Request.sendImportExport({
        method: 'POST',
        path: '/CustomerMessage/user/getListMessage',
        data ,
        cancelEvent
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getReceipt(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerReceipt/user/userGetListOneUser',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }

  static async getSystemNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/SystemNotification/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async findByIdSystemNotification(id) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/SystemNotification/user/getDetailById',
        data: {
          id
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailMess(id) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: '/CustomerMessage/user/getDetailMessage',
        data: {
          id
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
}
